import { SwatchSettings } from "./Swatches";

const InteractiveLogo = ({ activeSwatch, className }) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 1074.8 607" enableBackground="new 0 0 1074.8 607" xmlSpace="preserve" preserveAspectRatio="xMidYMid meet"
            style={{
                background: SwatchSettings[activeSwatch].background
            }}
            className={className}>
            <linearGradient id="Record_00000085971801031308126710000013766733864655967901_" gradientUnits="userSpaceOnUse" x1="144.0164" y1="346.4987" x2="377.0164" y2="346.4987">
                {SwatchSettings[activeSwatch].recordStops.map((stop, index) => {
                    return (
                        <stop key={index} offset={stop.offset} style={stop.style} />
                    );
                })}
            </linearGradient>
            <path id="Record_00000068654852594133030530000012294542607992823198_" fill="url(#Record_00000085971801031308126710000013766733864655967901_)" d="
            M281.5,346.5c0,11.6-9.4,21-21,21c-11.6,0-21-9.4-21-21c0-11.6,9.4-21,21-21C272.1,325.5,281.5,334.9,281.5,346.5z M260.8,285
            c33.8,0.1,61.3,27.7,61.3,61.5c0,11.1-3,21.5-8.1,30.5H373c2.6-9.7,4-20,4-30.5c0-64.2-52.3-116.5-116.5-116.5c-0.2,0-0.4,0-0.6,0
            c-0.8,0-1.5,0.4-1.8,1.1c-0.3,0.7-0.2,1.5,0.2,2.1c1.4,1.8,2.2,4,2.2,6.5c0,2.5-0.9,4.9-2.4,6.7c-1.2,1.5-1.2,3.6,0,5.1
            c1.5,1.8,2.4,4.1,2.4,6.7c0,2.5-0.9,4.9-2.4,6.7c-1.2,1.5-1.2,3.6,0,5.1c1.2,1.4,2,3.2,2.3,5.1c0.4,2.5-0.2,4.9-1.4,6.9
            c-0.4,0.6-0.4,1.4,0,2C259.4,284.6,260,285,260.8,285z M336,430.9c-2.2-0.5-4.3-1.6-5.8-3.4c-1.7-1.9-2.5-4.3-2.5-6.6
            c0-1.9-1.4-3.5-3.3-3.9c-2.3-0.4-4.5-1.6-6.2-3.5c-1.7-1.9-2.5-4.3-2.5-6.6c0-1.9-1.4-3.5-3.3-3.9c-2.3-0.4-4.5-1.6-6.2-3.5
            c-1.3-1.5-2.1-3.2-2.4-5c-0.1-0.7-0.6-1.3-1.3-1.5s-1.4-0.1-2,0.4c-10.7,9.2-24.7,14.7-39.9,14.7c-33.9,0-61.5-27.6-61.5-61.5
            c0-21.7,11.3-40.8,28.3-51.8c0.6-0.4,1-1.1,0.9-1.8c0-0.7-0.5-1.4-1.1-1.7c-1.6-0.8-3.1-2.1-4.1-3.7c-1.4-2.1-1.9-4.6-1.6-6.9
            c0.2-1.9-0.9-3.7-2.7-4.3c-2.2-0.7-4.3-2.2-5.6-4.4c-1.4-2.1-1.9-4.6-1.6-6.9c0.2-1.9-0.9-3.7-2.7-4.3c-2.2-0.7-4.2-2.2-5.6-4.3
            c-1.3-2-1.8-4.4-1.6-6.6c0.1-0.8-0.3-1.5-0.9-1.9c-0.6-0.4-1.5-0.4-2.1,0C165.8,268.5,144,305,144,346.5
            c0,64.2,52.3,116.5,116.5,116.5c29.2,0,55.9-10.8,76.4-28.6c0.6-0.5,0.8-1.3,0.6-2C337.3,431.6,336.7,431.1,336,430.9z"/>
            <linearGradient id="Logotype_00000052062713036473860400000008770211397087016363_" gradientUnits="userSpaceOnUse" x1="313.9265" y1="261.9987" x2="930.7543" y2="261.9987">
                {activeSwatch === "blackCarbon" ?
                    SwatchSettings[activeSwatch].recordStops.map((stop, index) => {
                        return (
                            <stop key={index} offset={stop.offset} style={stop.style} />
                        );
                    })
                    :
                    SwatchSettings[activeSwatch].typeStops.map((stop, index) => {
                        return (
                            <stop key={index} offset={stop.offset} style={stop.style} />
                        );
                    })
                }
            </linearGradient>
            <path id="Logotype_00000171695634536568253850000013351165450497656198_" fill="url(#Logotype_00000052062713036473860400000008770211397087016363_)" d="
            M456.5,377V233h55.6v144H456.5z M484.3,149c-17.4,0-31.5,14.1-31.5,31.5s14.1,31.5,31.5,31.5s31.5-14.1,31.5-31.5
            S501.7,149,484.3,149z M627.7,377h55.6v-85.5c0-34-27.5-61.5-61.5-61.5c-15.8,0-30.2,4.3-41.1,11.4V233l-55.6,0v144h55.6l0-78.5
            c0-13,10.5-23.5,23.5-23.5c13,0,23.5,10.5,23.5,23.5V377z M875.2,144v233h55.6V144H875.2z M376.3,377l77.1-144l-62.4,0l-77.1,144
            H376.3z M766.3,230c-41.4,0-75,33.6-75,75s33.6,75,75,75c17.6,0,32.8-6.3,40-15.4V377h55.9V233h-55.9v8.5
            C794.7,234.2,781,230,766.3,230z M809.3,305c0,17.4-14.1,31.5-31.5,31.5c-17.4,0-31.5-14.1-31.5-31.5s14.1-31.5,31.5-31.5
            C795.2,273.5,809.3,287.6,809.3,305z"/>
        </svg>
    );
};

export default InteractiveLogo;