const NotFound = () => {
    return (
        <section id="out-of-bounds">
            <div className="container">
                404 Not Found
            </div>
        </section>
    );
};

export default NotFound;